import React, { useEffect, useRef, useState } from "react";

import CustomButton from "../../../core/ButtonDefault";
import gsap from "gsap/all";
import { payToLocaleString } from "../../../../utils/utils";

const logos = {
  wert: "Wert",
  simplex: "Simplex",
  nowpayments: "NowPayments",
};

const refreshBy = 20;

const ProviderRow = ({ selected, setSelected, data }) => {
  const { provider, response, type } = data;

  if (type !== "success" || provider == "wert") return null;

  return (
    <div
      className={
        "row-providers--provider df fd-c br-12" +
        (selected?.provider == provider ? " active" : "")
      }
      key={provider}
      onClick={setSelected}
    >
      <div className="w-full df jc-sb ai-st">
        <div className="df ai-c gap-10">
          <div className="row-providers--dot dot ar-1 icon-24"></div>
          <div className={"row-providers--logo df cover icon_" + logos[provider] }>
            {/* <img src={logos[provider]} alt="" /> */}
          </div>
          <div className={"row-providers--logo df cover icon_" + logos.nowpayments }>
            {/* <img src={logos.nowpayments} alt="" /> */}
          </div>
          <div className={"row-providers--logo df cover icon_" + logos.wert }>
            {/* <img src={logos.wert} alt="" /> */}
          </div>
        </div>
        <div className="df fd-c ai-en gap-4">
          <p className="fixel_22">
            You Pay: {payToLocaleString(response.from.amount)}{" "}
            <span className="uppercase">{response.from.code}</span>
          </p>
          <p className="fixel_14 color-neu mr-t-4">(Including fees)</p>
        </div>
      </div>
      <div className="df jc-sb fixel_16_md">
        <p className="color-neu">Exchange Rate</p>
        <p className="uppercase">
          1 TICO = {response.rate} {response.from?.code}
        </p>
      </div>
      <div className="full-line w-full"></div>
      <div className="df jc-sb fixel_22">
        <p>You Get</p>
        <p>
          {payToLocaleString(response.to.amount)} {response.to?.code || "TICO"}
        </p>
      </div>
    </div>
  );
};

export default function Providers({ back, toConfirm, children, getProviders }) {
  const [selectedProvider, setSelectedProvider] = useState({});
  const progressRef = useRef();
  const secondsRef = useRef();
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const tl = gsap.timeline();
    const progres = progressRef.current;
    const seconds = secondsRef.current;

    const fetchProviders = async () => {
      const Providers = await getProviders();
      setProviders(Providers);
    };

    const onUpdate = () => {
      seconds.innerHTML = (refreshBy * (1 - tl.progress())).toFixed() + "s";
    };

    fetchProviders();

    tl.to(progres, {
      duration: refreshBy,
      ease: "none",
      transform: "none",
      onUpdate,
      onComplete() {
        fetchProviders();
        tl.restart();
      },
    });

    return () => {
      tl.revert().kill();
    };
  }, []);

  return (
    <>
      <div className="row-providers">
        {providers.length &&
          providers.map((data) => (
            <ProviderRow
              key={data.provider}
              selected={selectedProvider}
              setSelected={() => setSelectedProvider(data)}
              {...{ data }}
            />
          ))}
      </div>

      {children}

      <div className="refresh df fd-c ai-c gap-10 text-center fixel_16_md">
        <div className="line w-full br-16">
          <div className="progress" ref={progressRef}></div>
        </div>
        <p>
          Exchange rate will refresh again in{" "}
          <span className="color-sec" ref={secondsRef}>
            17s
          </span>
        </p>
      </div>
      <div className="buttons df jc-sb">
        <CustomButton
          onClick={back}
          classname={`go-back grey`}
          def={40}
          title={"Cancel"}
        />
        <CustomButton
          disabled={!selectedProvider?.provider}
          onClick={()=>toConfirm(selectedProvider)}
          classname={`go-buy pr`}
          def={40}
          title={"Confirm"}
        />
      </div>
    </>
  );
}
