import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import store from "./redux/state";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { DataProvider } from "./context/DataProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import FeatureFlagsProvider from './context/FeatureFlagsProvider';
import AutoLoginLinkProvider from './context/AutoLoginLinkProvider';
import ConversionParamsProvider from './context/ConversionParamsProvider';

if (process.env.ENVIRONMENT) {
  Sentry.init({
    dsn: "https://99b46a3a1969aca19dbad17dff15a007@o4506519499309056.ingest.us.sentry.io/4507435565056000",
    integrations: [
      // Sentry.replayIntegration(),
      // Sentry.feedbackIntegration({
      //   // Additional SDK configuration goes in here, for example:
      //   colorScheme: "system",
      // }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.3,
    environment: process.env.ENVIRONMENT,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

let rerenderEntireTree = (state) => {
  root.render(
    <BrowserRouter basename={state.basePath}>
      <ConversionParamsProvider>
        <AuthProvider>
          <AutoLoginLinkProvider>
            <FeatureFlagsProvider state={store}>
              <DataProvider>
                <GoogleOAuthProvider clientId="837411321679-cgnr28oavedi7jabeiha7elsbcdq8adh.apps.googleusercontent.com">
                  <App state={store} />
                </GoogleOAuthProvider>
              </DataProvider>
            </FeatureFlagsProvider>
          </AutoLoginLinkProvider>
        </AuthProvider>
      </ConversionParamsProvider>
    </BrowserRouter>
  );
};

rerenderEntireTree(store.getState());

store.subscribe(rerenderEntireTree);
