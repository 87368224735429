import React, { useEffect, useState } from "react";
import wallet_qr from "../../../../assets/img/wallet/wallet_qr.jpg";
import Input from "../../../core/labels/Input";

import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomButton from "../../../core/ButtonDefault";
import useAxiosREST from "../../../../utils/hooks/useAxiosAPI";
import Select from "../../../core/labels/Select";

const steps = [
  "Go to your wallet",
  "Select $TICO as currency to be sent",
  "Select Avalance for the network",
  "Copy and paste the following wallet address into the designated field:",
  "After checking that everything is in order, proceed with the deposit",
  "After you send the funds, you can safely close this window and wait.",
];

let sto = null;

const Title = ({ id }) => {
  return (
    <p className="df gap-6">
      <span>0{id}</span> {steps[id - 1]}
    </p>
  );
};

export default function Flow() {
  const [copy, setCopy] = useState(false);
  const [defChain, setDefChain] = useState("");
  const [selectedChain, setSelectedChain] = useState("");
  const [chainList, setChainList] = useState(window["available-chains"] || {});
  const [depositAddress, setDepositAddress] = useState({ address: "0x" });
  const { fetchAPI } = useAxiosREST();

  const onCopy = () => {
    sto && clearTimeout(sto);
    setCopy(true);
    sto = setTimeout(() => setCopy(false), 1500);
  };

  const selectChainID = (e) => {
    setSelectedChain(e.target.value);
  };

  const getAvailableChains = async () => {
    let chains = window["available-chains"];
    if (!chains) {
      // const url = `cashier/direct-deposit/available-chains`;
      // chains = await fetchAPI.get(url); //TODO
    }
    chains = [
      {
        chainId: 1,
        chainName: "Test Avalanche 1",
        chainIcon: null,
        requiredConfirmations: 12,
        addressType: null,
      },
      {
        chainId: 2,
        chainName: "Test Avalanche 2",
        chainIcon: null,
        requiredConfirmations: 12,
        addressType: null,
      },
      {
        chainId: 3,
        chainName: "Test Avalanche 3",
        chainIcon: null,
        requiredConfirmations: 12,
        addressType: null,
      },
    ];

    window["available-chains"] = chains;

    if (chains.length) {
      const chainsObject = {};
      chains.forEach(
        (r) => r.chainId && (chainsObject[r.chainId] = r.chainName)
      );
      setDefChain(chains[0].chainName);
      setSelectedChain(chains[0].chainId);
      setChainList(chainsObject);
    }
  };
  const getDepositAddress = async (chainId) => {
    const url = `cashier/direct-deposit/deposit-address`;

    // const ress = await fetchAPI.get(url, {chainId});  //TODO

    const ress = {
      address: "0x4cf8abfc850db9cd27f8e3c35c2d3bc6b3cc9493",
      memo: null,
      tag: null,
    };

    ress && setDepositAddress(ress);
  };

  useEffect(() => {
    getAvailableChains();
    return () => {};
  }, []);

  useEffect(() => {
    selectedChain && getDepositAddress(selectedChain);
    return () => {};
  }, [selectedChain]);

  return (
    <>
      <p className="fixel_22 text-center">
        To deposit $TICO from your wallet <br /> follow the instructions below:
      </p>
      <div className="list-rows df fd-c fixel_16_md">
        <Title id={1} />
        <Title id={2} />
        <Title id={3} />
        {selectedChain && (
          <Select
            handleChange={selectChainID}
            list={chainList}
            def={defChain}
            value={selectedChain}
          />
        )}
        <Title id={4} />

        <div className="wallet-ddress df aic-">
          <Input
            readOnly={true}
            value={depositAddress.address}
            name="walletAddress"
          />
          <CopyToClipboard text={depositAddress.address} onCopy={onCopy}>
            <div
              className={"icon_copy icon-24 ar-1" + (copy ? " copyed" : "")}
            ></div>
          </CopyToClipboard>
        </div>

        {depositAddress.qr && (
          <div className="qr as-c df cover ar-1">
            <img src={depositAddress.qr} alt="" />
          </div>
        )}

        <Title id={5} />
        <Title id={6} />

        {/* <CustomButton
          onClick={back}
          classname={`go-back grey as-c`}
          def={40}
          title={"Back"}
        /> */}
      </div>
    </>
  );
}
