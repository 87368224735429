import React, { useEffect, useState } from "react";
import CustomButton from "../core/ButtonDefault";

export default function Confirm({ state, data }) {
  // useEffect(() => {
  //   return () => {
  //     data.close_callback?.();
  //   };
  // }, []);

  const handleClose = () => {
    state.setPopUp("");
    data.close_callback && data.close_callback();
  };

  return (
    <>
      <div className="title">
        {data.title ? <p className="t fixel_26">{data.title}</p> : null}
        {data.subtitle ? (
          <p
            className="s"
            dangerouslySetInnerHTML={{ __html: data.subtitle }}
          ></p>
        ) : null}
      </div>
      {data?.custom_data}

      {data?.close || data.corfirm ? (
        <div
          className={`corfirm_buttons df ${
            data.close && data.corfirm ? "double" : " jc-c sing"
          } `}
        >
          {data?.close ? (
            <CustomButton
              classname="grey"
              def={40}
              title={data.close}
              onClick={handleClose}
            />
          ) : null}
          {data?.corfirm ? data.corfirm() : null}
        </div>
      ) : null}
    </>
  );
}
