import React, { useEffect, useState } from "react";
import Input from "../../../core/labels/Input";

import CustomButton from "../../../core/ButtonDefault";
import SelectCurrency from "./SelectCurrency";
import Providers from "./Providers";
import useAxiosREST from "../../../../utils/hooks/useAxiosAPI";
import currencies from "./data/fiat-currencies.json";
import providersJson from "./data/fiat-providers.json";
import { payToLocaleString } from "../../../../utils/utils";

window["fiat-currencies"] = currencies; //TODO

const counts = [10, 25, 50, 100];

const labelPlay = {
  placeholder: "Enter Amount",
  name: "amount",
  type: "number",
  autoComplete: "false",
  required: true,
  tip: { title: "I want to pay" },
};

const labelBonus = {
  placeholder: "Enter Bonus Code",
  name: "amount",
  autoComplete: "false",
  required: true,
  tip: { title: "Bonus Code (Optional)" },
};

export default function Fiat(props) {
  const { back, onClose, state } = props;
  const [amount, setAmount] = useState("");
  const [bonusCode, setBonusCode] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [isProviders, setIsProviders] = useState(false);
  const { fetchAPI } = useAxiosREST();
  const [currenciesArray, setCurrencies] = useState([]);
  const [selectedArray, setSelectedArray] = useState({});
  const [exchangeResult, setExchangeResult] = useState({});
  const [amountError, setAmountError] = useState(null);

  const goToProviders = () => {
    setIsProviders(true);
  };

  const getExchangeProviders = async () => {
    const url = `cashier/fiat/estimated-exchange-result-for-all-providers`;
    const params = {
      fromCurrencyAlpha3: currency,
      fromCurrencyAmount: amount,
    };
    // const ress = await fetchAPI.get(url, params); //TODO
    const ress = providersJson;

    return ress;
  };

  const toConfirm = (provider) => {
    const url = `cashier/fiat/providers/simplex/create-payment-session`;

    const params = {
      paymentUuid: window.uuid,
      quoteId: provider.response.quote_id,
    };

    // const ress = await fetchAPI.post(url, params);  //TODO

    const ress = {
      message: "Payment initialized",
    };

    if (ress.message) {
      //close Wallet popup
      window.quote_id = provider.response.quote_id;
      onClose();
      state.setSimplexWidget(true);
    }

    // show popup on Success
    // state.setPopUp("confirm", {
    //   title: "Purchase Completed",
    //   subtitle:
    //     "Your transfer has been received! <br> <br> You will be notified as soon as the transaction has been successfully confirmed!",
    //   close: "Let’s Play!",
    // });
  };

  // Get all available currencies
  useEffect(() => {
    const getAvailableCurrencies = async () => {
      let available = window["fiat-currencies"];
      if (!available) {
        available = await fetchAPI.get(`cashier/fiat-available-currencies`); //TODO
      }
      setCurrencies(available);
    };
    setCurrencies();
    getAvailableCurrencies();

    return () => {};
  }, []);

  // Get selected currency data (need min and max)
  useEffect(() => {
    if (currency && currenciesArray.length) {
      const selectedCurr = currenciesArray.find((c) => c.code == currency);
      setSelectedArray(selectedCurr);
    }

    return () => {};
  }, [currency, currenciesArray]);

  // Show error if have min/max
  useEffect(() => {
    let err = null;
    if (amount) {
      const { min, max } = selectedArray;
      if (
        Boolean(min || max) &&
        (amount < Number(min) || amount > Number(max))
      ) {
        err = `Minimum: ${min} ${currency}, maximum: ${max} ${currency}`;
      }
    }
    setAmountError(err);
    return () => {};
  }, [amount, selectedArray]);

  // Get estimated exchange result
  useEffect(() => {
    let sto = null;
    let stoFirst = null;

    const getExchangeResult = async () => {
      sto && clearTimeout(sto);

      const url = `cashier/fiat/estimated-exchange-result`;

      const params = {
        fromCurrencyAlpha3: currency,
        fromCurrencyAmount: amount,
      };

      // const ress = await fetchAPI.get(url, params);  //TODO
      const ress = {
        rawExchangeRate: "0.000018652547800795892",
        exchangeRate: "0.000",
        tico: "53612515.06",
        fromCurrencyAmount: "1000.01",
        fromCurrencyAlpha3: "USD",
      };

      setExchangeResult(ress);
      sto = setTimeout(getExchangeResult, 1000);
    };

    if (amount && !isProviders) {
      stoFirst = setTimeout(getExchangeResult, 500);
    }

    return () => {
      sto && clearTimeout(sto);
      stoFirst && clearTimeout(stoFirst);
    };
  }, [amount, currency, isProviders]);

  return isProviders ? (
    <Providers
      back={() => setIsProviders(false)}
      toConfirm={toConfirm}
      getProviders={getExchangeProviders}
    />
  ) : (
    <>
      <div className="buy-fiat df fd-c">
        <div className="row-amount df ai-st">
          <Input
            {...labelPlay}
            value={amount}
            error={amountError}
            handleChange={(e) => setAmount(e.target.value)}
          />
          <SelectCurrency
            currencys={[...currenciesArray].map((c) => c?.code).sort()}
            value={currency}
            onChange={setCurrency}
            {...props}
          />
        </div>

        <div className="row-counts df w-full fixel_16_sbl">
          {counts.map((count) => (
            <div className="count df ai-c br-14" key={count}>
              <button
                disabled={amount < count}
                className="cta mm ar-1 icon-24"
                onClick={(e) => setAmount((e) => Number(e) + count * -1)}
              />
              <p>{count}</p>
              <button
                className="cta pp ar-1 icon-24"
                onClick={(e) => setAmount((e) => Number(e) + count)}
              />
            </div>
          ))}
        </div>

        {Boolean(amount) && (
          <div className="row-receive df jc-sb">
            <p className="fixel_16_md">...to receive</p>

            <div className="df fd-c gap-6">
              <div className="df ai-c gap-6">
                <div className="tico_icon"></div>
                <p className="fixel_22">
                  {payToLocaleString(exchangeResult?.tico)}
                </p>
              </div>
              <p className="fixel_14 color-neu">
                1 TICO ={" "}
                {exchangeResult?.fromCurrencyAmount / exchangeResult?.tico}{" "}
                {currency}
              </p>
            </div>
          </div>
        )}
      </div>

      <Input
        {...labelBonus}
        value={bonusCode}
        handleChange={(e) => setBonusCode(e.target.value)}
      />

      <div className="buttons df jc-sb">
        <CustomButton
          onClick={back}
          classname={`go-back grey`}
          def={40}
          title={"Cancel"}
        />
        <CustomButton
          onClick={goToProviders}
          classname={`go-buy pr`}
          def={40}
          disabled={!amount || amountError}
          title={"Buy"}
        />
      </div>
    </>
  );
}
