import React, { useContext, useState } from "react";
import axios from "axios";
import AuthContext from "../../context/AuthProvider";
import store from "../../redux/state";
import { isOpenRegistationFormPresentInUrl } from "../isOpenRegistationForm";

const useAxiosREST = () => {
  const [isFetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const { auth, setAuth } = useContext(AuthContext);

  const bearer = auth?.token ? { Authorization: `Bearer ${auth.token}` } : {};
  // Create axios instance
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...bearer,
    },
  });

  const checkError = (err, end = "") => {
    const msg = err.response?.data?.message;

    switch (msg) {
      case "Unauthenticated.":
        localStorage.removeItem("user_country");
        setAuth({ token: null });
        if (end !== "user/profile") {
          if (isOpenRegistationFormPresentInUrl()) {
            store.setPopUp("reg");
          } else {
            store.setPopUp("login");
          }
        }
        break;
      case "Server Error":
        store.setPopUp("confirm", {
          title: "Something went wrong",
          subtitle: "Server Error",
          close: "Okay",
        });
        break;
    }
    setFetching(false);
    setError(err);
  };

  const fetchAPI = {
    get: async (end, params = null) => {
      setFetching(true);
      try {
        const res = await client.get(end, { params });
        setFetching(false);
        return res.data;
      } catch (error) {
        checkError(error, end);

        switch (error.response.status) {
          case 404:
            return { not_found: true, ...error.response?.data };
          default:
            return error.response?.data;
        }
      }
    },
    post: async (end, data = null) => {
      setFetching(true);
      try {
        const res = await client.post(end, data);

        setFetching(false);
        return res.data;
      } catch (error) {
        checkError(error);
        return error.response?.data;
      }
    },
    delete: async (end, data = null) => {
      setFetching(true);
      try {
        const res = await client.delete(end, { data: data });
        setFetching(false);
        return res.data;
      } catch (error) {
        checkError(error);
        return error.response?.data;
      }
    },

    patch: async (end, data = null) => {
      setFetching(true);
      try {
        const res = await client.patch(end, data);
        setFetching(false);
        return res.data;
      } catch (error) {
        checkError(error);
        return error.response?.data;
      }
    },
    put: async (end, data = null) => {
      setFetching(true);
      try {
        const res = await client.put(end, data);
        setFetching(false);
        return res.data;
      } catch (error) {
        checkError(error);
        return error.response?.data;
      }
    },
  };

  return { isFetching, error, fetchAPI };
};

export default useAxiosREST;
