import { FeaturesReady, GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import Preloader from '../components/layout/Preloader';

const featureFlags = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: process.env.ENVIRONMENT === "production" ? "sdk-hMYdrBTIA5ADYoyz" : "sdk-tAYzjAmyPjhMnBcJ",
  enableDevMode: process.env.ENVIRONMENT !== "production"
});

featureFlags.init({ streaming: false })

export { featureFlags }

export default function FeatureFlagsProvider({ children, state }) {
  return (
    <GrowthBookProvider growthbook={featureFlags}>
      <FeaturesReady timeout={1500} fallback={<Preloader state={state} />}>
        {children}
      </FeaturesReady>
    </GrowthBookProvider>)
}