import React, { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useGoogleLogin } from "@react-oauth/google";

// import useForm from "../../../utils/hooks/useForm";
import FB from "../../../assets/img/icons/facebook.svg";
import GG from "../../../assets/img/icons/google.svg";
import AuthContext from "../../../context/AuthProvider";
import { useContext } from "react";
import CustomButton from "../../core/ButtonDefault";
import useAxiosREST from "../../../utils/hooks/useAxiosAPI";

const EnterViaSocials = ({ state }) => {
  const { auth, setAuth } = useContext(AuthContext);
  const mobile = state.getState().isMobile;
  const { fetchAPI } = useAxiosREST();

  const onLoginError = (data) => {
    const msg = data?.message;
    state.setPopUp("confirm", {
      title: "Something went wrong",
      subtitle: msg || "Please try again later",
      close: "Okey",
    });
  };

  const getFetch = async (r) => {
    state.setPopUp("");
    const token = r?.access_token;
    const data = { access_token: token || "" };

    if (!token) {
      onLoginError(null);
      return;
    }
    const res = await fetchAPI.get("auth/google/callback", data);
    if (res?.success) setAuth({ token: res.token });
  };

  const initLogin = (user) => {
  };

  const responseGoogle = useGoogleLogin({
    onSuccess: getFetch,
    onError: onLoginError,
    onNonOAuthError: onLoginError,
  });
  const responseFacebook = (profile) => {
    if (!profile.accessToken) return;

    const { id, name, email, picture } = profile;
    let u = name.split(" ");
    initLogin("facebook", id, u[0], u[1], email, picture.data.url);
  };

  return (
    <div className="via_soc df fd-c ai-c">
      <p className="fixel_16_sbl">Continue with</p>

      <div className="btns df mr-t-16">
        <CustomButton
          onClick={responseGoogle}
          classname="tr link"
          def={mobile ? 40 : 32}
        >
          <span className="icon df">
            <GG></GG>
          </span>
          <span className="text">Google</span>
        </CustomButton>
        <FacebookLogin
          appId="868406717594069"
          fields="name, email, picture"
          autoLoad={false}
          scope="public_profile, email"
          render={(r) => (
            <CustomButton
              classname="tr link"
              def={mobile ? 40 : 32}
              onClick={r.onClick}
            >
              <span className="icon df">
                <FB></FB>
              </span>
              <span className="text">Facebook</span>
            </CustomButton>
          )}
          callback={responseFacebook}
        />
      </div>
    </div>
  );
};

export default EnterViaSocials;
