import React, { useEffect, useState } from "react";
import CustomButton from "../core/ButtonDefault";

export default function Success({ state, data }) {
  useEffect(() => {
    return () => {};
  }, []);

  const handleClose = () => {
    state.setPopUp("");
    data.close_callback && data.close_callback();
  };

  return (
    <>
      <div className="title">
        {data.icon ? <div className="icon df cover br-8"></div> : null}
        <p className="t fixel_26 ">{data.title}</p>
        {data.subtitle ? <p className="s">{data.subtitle}</p> : null}
      </div>
      {data?.custom_data}
      <div
        className={`corfirm_buttons df ${
          data.close && data.corfirm ? "double" : " jc-c sing"
        } `}
      >
        {data.close ? (
          <CustomButton
            classname="type-40 br-16 tr"
            title={data.close}
            onClick={handleClose}
          />
        ) : null}
        {data.corfirm ? data.corfirm() : null}
      </div>
    </>
  );
}
