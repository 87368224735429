import React, {
  createContext,
  useState,
  useEffect,
  lazy,
  useMemo,
} from "react";
import { featureFlags } from "./FeatureFlagsProvider";

const DataContext = createContext({});

const pages = [
  { path: "*", temp: "404" },
  { path: "/", temp: "Home" },
  // { path: "/about-us", temp: "About" },
  { path: "/games/:slug", temp: "Game" },
  { path: "/marketplace", temp: "Market" },
  { path: "/marketplace/:slug", temp: "Asset" },
  { path: "/reset-password", temp: "Reset" },
  { path: "/backpack", temp: "Backpack" },
  { path: "/tournaments", temp: "Tournaments" },
  {
    path: "/community-leaderboard",
    temp: "Community",
    featureFlag: "communities-leaderboard",
  },
  { path: "/loot-packs", temp: "LootPacks" },
  {
    path: "/tournaments/:slug",
    temp: "Tournament",
    // subpage: [{ path: "leaderboard", temp: "Leaderboard" }],
  },
  { path: "/promotions", temp: "Promotions" },
  { path: "/loyalty", temp: "Loyalty" },
  { path: "/faq", temp: "FAQ" },
  { path: "/terms-and-conditions", temp: "Terms" },
  { path: "/privacy-policy", temp: "Policy" },
  { path: "/scratch-cards", temp: "Scratch" },
  { path: "/capsules", temp: "Capsules" },
  {
    path: "/wheel-of-fortune",
    temp: "Wheel",
    featureFlag: "wheel-of-fortune",
  },
  {
    path: "/profile",
    temp: "Profile",
    subpage: [
      { path: "settings", temp: "Settings" },
      {
        path: "verification",
        temp: "Verification",
        featureFlag: "identity-verification",
      },
      { path: "rank-and-progress", temp: "Progress" },
      { path: "history", temp: "History" },
      { path: "appearance", temp: "Appearance" },
    ],
  },
  {
    path: "/mega-tournament",
    temp: "Mega",
  },
];

function getRoutes(allPages) {
  function getPageRoute({ temp, subpage, ...page }) {
    const f = temp.toLowerCase();
    const component = lazy(() => import(`../views/${f}/${temp}`));

    const child = subpage?.map(({ path, temp }) => {
      return {
        path,
        component: lazy(() => import(`../views/${f}/${f}/${temp}`)),
      };
    });

    return { ...page, component, children: child };
  }

  const routes = allPages.reduce((acc, page) => {
    acc.push(getPageRoute(page));

    return acc;
  }, []);

  return routes;
}

function filterPages(pages) {
  return pages.reduce((filtered, page) => {
    // Check if the page has a featureFlag and if it is enabled

    if (!page.featureFlag || featureFlags.isOn(page.featureFlag)) {
      // If the page has subpages, recursively filter them as well
      if (page.subpage) {
        page.subpage = filterPages(page.subpage);
      }
      // Add the page to the filtered list
      filtered.push(page);
    }
    return filtered;
  }, []);
}

export function DataProvider({ children }) {
  const [data, setData] = useState(null);

  const pagesAfterFeatureFlags = useMemo(() => filterPages(pages), []);

  const fetchData = async () => {
    const [data] = await Promise.all([]);
    setData({ ...data, routes: getRoutes(pagesAfterFeatureFlags) });
  };

  useEffect(() => {
    fetchData();

    return () => {};
  }, []);

  return (
    <DataContext.Provider value={{ data }}>{children}</DataContext.Provider>
  );
}

export default DataContext;
