import React, { useContext } from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import store from "../../redux/state";
import AuthContext from "../../context/AuthProvider";

const DelayLink = ({ children, to, need_auth, cd, myRef = null, ...props }) => {
  let timeout = null;
  let navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [timeout]);

  const handleClick = (e) => {
    cd?.(e)
    if (location?.pathname === to) return;
    if (e.defaultPrevented || props?.target == "_blank") return;

    e.preventDefault();

    if (need_auth && !auth.token) {
      console.trace("Need auth");
      store.setPopUp("login");
      return;
    }

    if (props?.disabled) return;
    store.setLoading(true);
    timeout = setTimeout(() => navigate(to), 300);
  };

  return to ? (
    <NavLink to={to} onClick={handleClick} {...props} end ref={myRef}>
      {children}
    </NavLink>
  ) : (
    <div {...props}>{children}</div>
  );
};

export default DelayLink;
