import React, { useEffect, useState } from "react";
import KYC from "./components/Deposit-KYC";
import Flow from "./components/Deposit-Flow";

const parts = {
  kyc: KYC,
  flow: Flow,
};

export default function Deposit(props) {
  const Component = parts[props.isUserKYC ? "flow" : "kyc"];

  return (
    <>
      <Component {...props} />
    </>
  );
}
