import React, { useEffect, useState } from "react";
import Input from "../../../core/labels/Input";

import CustomButton from "../../../core/ButtonDefault";
import { isValidAddress } from "../../../../utils/utils";

const counts = ["1/4", "1/2", "3/4", "1"];

export default function Flow(props) {
  const { changePart, isUserKYC, changeTab, amountState, addressState } = props;
  const [amountValue, setAmount] = amountState;
  const [addressValue, setWalletAddress] = addressState;
  const [isWalletOk, setIsWalletOk] = useState(null);

  const labelPlay = {
    placeholder: "Enter Amount",
    name: "amount",
    type: "number",
    value: amountValue,
    autoComplete: "false",
    required: true,
    error: !isUserKYC,
    tip: { title: "I want to withdraw" },
    handleChange: (e) => setAmount(e.target.value),
  };

  const labelAddress = {
    placeholder: "Enter Your Wallet Address...",
    name: "amount",
    value: addressValue,
    autoComplete: "false",
    description: "Note: Address should be ERC-20 compliant.",
    required: true,
    error: isWalletOk == null ? "" : isWalletOk ? "" : " ",
    isOk: isWalletOk,
    minLength: 15,
    tip: { title: "...to my wallet" },
  };

  const onAddressChange = (e) => {
    const v = e.target.value;
    const isValid = v ? isValidAddress(v) : null;
    setIsWalletOk(isValid);
    setWalletAddress(v);
  };

  return (
    <>
      <div className="tico-information df fd-c gap-16">
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">Current Balance</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">5,000</p>
          </div>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">
            Locked Balance (unlocks in xx days)
          </p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">5,000</p>
          </div>
        </div>

        <div className="full-line"></div>

        <div className="row df jc-sb ai-c gap-10 fixel_22">
          <p>Available To Withdraw</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p>5,000</p>
          </div>
        </div>
      </div>
      <div className="buy-fiat df fd-c">
        <div className="df fd-c">
          <Input {...labelPlay}>
            <p className="right-tip">$TICO</p>
          </Input>
          <p className="fixel_14 kyc-error color-red mr-t-8">
            In order to be able to withdraw this amount of $TICO, you need a{" "}
            <span className="text-underline pointer">Verified Account.</span>
            <br />
            Please read the{" "}
            <span
              className="text-underline pointer"
              onClick={() => changeTab("info")}
            >
              Info Tab
            </span>{" "}
            for more details.
          </p>
        </div>

        <div className="row-counts df w-full fixel_16_sbl">
          {counts.map((count) => (
            <button
              className="count df ai-c br-14"
              key={count}
              onClick={(e) => setAmount((e) => Number(e) + count)}
            >
              <div className="w-full df jc-c">
                <p>{count == 1 ? "MAX" : count}</p>
              </div>
            </button>
          ))}
        </div>

        <Input {...labelAddress} handleChange={onAddressChange} />
      </div>

      <div className="buttons df jc-c">
        <CustomButton
          onClick={() => changePart("confirm")}
          classname={`go-buy pr`}
          def={40}
          disabled={!amountValue || !addressValue}
          title={"Withdraw"}
        />
      </div>
    </>
  );
}
